import React from 'react';
import logo from './logo.svg';
import './App.css';
import ImageChooserController from './controllers/ImageChooserController';

function App() {
	return (
		<ImageChooserController/>
	);
}

export default App;
