import { useEffect, useRef, useState } from "react";
import { playSound } from "../services/sound-player";
import logger from "../services/logger";
import assert from "assert";
import sample from "lodash.sample";
import shuffle from 'lodash.shuffle';
import ImageChooserQuadView from "../components/ImageChooserQuadView";

interface ImageChooserControllerProps {
}

export type ImageSetItem = {
	id: string,
	imageUrl: string,
	imageContain?: boolean,
	audioUrl: string,
};

function isImageSetItem(item: any): item is ImageSetItem {
	return (item as ImageSetItem).imageUrl !== undefined;
}

export type ImageSetMultiItem = {
	id: string,
	imageUrls: string[],
	imageContain?: boolean,
	audioUrls: string[],
};
  
type ImageSets = {
	[category: string]: (ImageSetItem|ImageSetMultiItem)[];
};

const imageSets: ImageSets = {
	"alle": [
		{id: "mama", imageUrls: ["https://hydrogen-assets.kreativrauschen.de/privat/helen.jpg"],
			audioUrls: [
				require("../assets/erste-worte/mama.mp3"),
				require("../assets/erste-worte/mama-2.m4a"),
				require("../assets/erste-worte/mama-3.m4a"),
				require("../assets/erste-worte/mama-4.m4a"),
			]
		},
		{id: "papa",
			imageUrls: [
				"https://hydrogen-assets.kreativrauschen.de/privat/florian.jpg"
			],
			audioUrls: [
				require("../assets/erste-worte/papa.mp3"),
				require("../assets/erste-worte/papa-2.m4a"),
				require("../assets/erste-worte/papa-3.m4a"),
				require("../assets/erste-worte/papa-4.m4a"),
			]
		},
		{id: "janosch",
			imageUrls: [
				"https://hydrogen-assets.kreativrauschen.de/privat/janosch.jpg",
			],
			audioUrls: [
				require("../assets/erste-worte/janosch.m4a"),
				require("../assets/erste-worte/janosch-2.m4a"),
				require("../assets/erste-worte/janosch-3.m4a"),
				require("../assets/erste-worte/janosch-4.m4a"),
			]
		},
		// {id: "gisela-sander", imageUrl: "https://hydrogen-assets.kreativrauschen.de/privat/gisela-sander.jpg", audioUrl: require("../assets/erste-worte/oma.mp3")},
		// {id: "gisela-sommer", imageUrl: "https://hydrogen-assets.kreativrauschen.de/privat/gisela-sommer.jpg", audioUrl: require("../assets/erste-worte/oma.mp3")},
		{id: "hund",
			imageUrls: [
				require("../assets/erste-worte/hund.jpg"),
				require("../assets/erste-worte/hund-2.jpg"),
				require("../assets/erste-worte/hund-3.jpg"),
				require("../assets/erste-worte/hund-4.jpg"),
				require("../assets/erste-worte/hund-5.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/hund.m4a"),
				require("../assets/erste-worte/hund-2.m4a"),
				require("../assets/erste-worte/hund-3.m4a"),
				require("../assets/erste-worte/hund-mixkit-dog-barking-twice-1.mp3"),
			]
		},
		{id: "katze",
			imageUrls: [
				require("../assets/erste-worte/katze.jpg"),
				require("../assets/erste-worte/katze-2.jpg"),
				require("../assets/erste-worte/katze-3.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/katze.m4a"),
				require("../assets/erste-worte/katze-2.m4a"),
				require("../assets/erste-worte/katze-3.m4a"),
				require("../assets/erste-worte/katze-4.m4a"),
				require("../assets/erste-worte/katze-mixkit-sweet-kitty-meow-93.mp3"),
			]
		},
		{id: "schaf",
			imageUrls: [
				require("../assets/erste-worte/schaf.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/schaf.m4a"),
				require("../assets/erste-worte/schaf-2.m4a"),
				require("../assets/erste-worte/schaf-Sheep-Lamb-Bah-C-www.fesliyanstudios.com.mp3"),
				require("../assets/erste-worte/schaf-Sheep-Lamb-Bah-A-www.fesliyanstudios.com.mp3"),
			]
		},
		{id: "pferd",
			imageUrls: [
				require("../assets/erste-worte/pferd.jpg"),
			], 
			audioUrls: [
				require("../assets/erste-worte/pferd.m4a"),
				require("../assets/erste-worte/pferd-Horse-Neigh-Quick-B-www.fesliyanstudios.com.mp3"),
			]
		},
		{id: "maus",
			imageUrls: [
				require("../assets/erste-worte/maus.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/maus.webm"),
				require("../assets/erste-worte/maus-2.m4a"),
			]
		},
		{id: "ente",
			imageUrls: [
				require("../assets/erste-worte/ente.jpg"),
				require("../assets/erste-worte/ente-2.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/ente.m4a"),
				require("../assets/erste-worte/ente-2.m4a"),
				require("../assets/erste-worte/ente-607226__d4xx__ducks.mp3"),
			]
		},
		{id: "schwein",
			imageUrls: [
				require("../assets/erste-worte/schwein.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/schwein.m4a"),
				require("../assets/erste-worte/schwein-2.m4a"),
				require("../assets/erste-worte/schwein-536746__egomassive__pig.mp3"),
			]
		},
		{id: "apfel",
			imageUrls: [
				require("../assets/erste-worte/apfel.jpg"),
				require("../assets/erste-worte/apfel-2.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/apfel.m4a"),
				require("../assets/erste-worte/apfel-2.m4a"),
				require("../assets/erste-worte/apfel-3.m4a"),
			]
		},
		{id: "affe",
			imageUrls: [
				require("../assets/erste-worte/affe.jpg"),
				require("../assets/erste-worte/affe-2.jpg"),
				require("../assets/erste-worte/affe-StockCake-Contemplative-Gorilla-Pose_1714169090.jpg"),
				require("../assets/erste-worte/affe-StockCake-Pensive-Monkey-Portrait_1714169054.jpg"),
				require("../assets/erste-worte/affe-StockCake-Monkey-in-Nature_1714169050.jpg"),
				require("../assets/erste-worte/affe-StockCake-Chimpanzee-Tree-Climb_1714168565.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/affe.m4a"),
			]
		},
		{id: "bagger",
			imageUrls: [
				require("../assets/erste-worte/bagger.jpg"),
				require("../assets/erste-worte/bagger-StockCake-Excavator-at-Work_1713300973.jpg"),
				require("../assets/erste-worte/bagger-StockCake-Dusty-Excavation-Site_1713301097.jpg"),
				require("../assets/erste-worte/bagger-StockCake-Construction-Site-Activity_1713301056.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/bagger.m4a"),
				require("../assets/erste-worte/bagger-2.m4a"),
				require("../assets/erste-worte/bagger-3.m4a"),
			]
		},
		{id: "auto",
			imageUrls: [
				require("../assets/erste-worte/auto.jpg"),
				require("../assets/erste-worte/auto-StockCake-Scenic-Drive-Sunset_1713896600.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/auto.m4a"),
			]
		},
		{id: "fahrrad", imageUrl: require("../assets/erste-worte/fahrrad.jpg"), audioUrl: require("../assets/erste-worte/fahrrad.m4a")},
		{id: "kuh",
			imageUrls: [
				require("../assets/erste-worte/kuh.jpg"),
				require("../assets/erste-worte/kuh-2.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/kuh.m4a"),
				require("../assets/erste-worte/kuh-mixkit-cow-moo-1744.mp3"),
			]
		},
		{id: "igel",
			imageUrls: [
				require("../assets/erste-worte/igel.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/igel.m4a"),
				require("../assets/erste-worte/igel-2.m4a"),
				require("../assets/erste-worte/igel-3.m4a"),
			]
		},
		{id: "stuhl",
			imageUrls: [
				require("../assets/erste-worte/stuhl.jpg"),
				require("../assets/erste-worte/stuhl-2.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/stuhl.m4a"),
				require("../assets/erste-worte/stuhl-2.m4a"),
			],
		},
		{id: "loewe",
			imageUrls: [
				require("../assets/erste-worte/loewe.jpg"),
				require("../assets/erste-worte/loewe-2.jpg"),
				require("../assets/erste-worte/loewe-StockCake-Majestic-Lion-Sunset_1713084151.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/loewe.m4a"),
				require("../assets/erste-worte/loewe-mixkit-big-wild-lion-growl-95.mp3"),
			],
		},
		{id: "fuchs",
			imageUrls: [
				require("../assets/erste-worte/fuchs-StockCake-Winter-Fox-Stroll_1713084068.jpg"),
				require("../assets/erste-worte/fuchs-StockCake-Majestic-Fox-Stance_1713084047.jpg"),
				require("../assets/erste-worte/fuchs-StockCake-Curious-Fox-Stare_1713084010.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/fuchs.m4a"),
				require("../assets/erste-worte/fuchs-2.m4a"),
			],
		},
		{id: "taube",
			imageUrls: [
				require("../assets/erste-worte/taube-StockCake-Pigeon-Urban-Perch_1713083423.jpg"),
				require("../assets/erste-worte/taube-StockCake-Urban-Pigeon-Stance_1713083382.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/taube.m4a"),
				require("../assets/erste-worte/taube-607224__d4xx__wood-pigeon-call.mp3"),
			],
		},
		{id: "pinguin",
			imageUrls: [
				require("../assets/erste-worte/pinguin-StockCake-Majestic-Penguin-Strut_1713083124.jpg"),
				require("../assets/erste-worte/pinguin-StockCake-Penguin-in-Ice_1713083268.jpg"),
				require("../assets/erste-worte/pinguin-StockCake-Penguin-on-Ice_1713083140.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/pinguin.m4a"),
			],
		},
		{id: "rakete",
			imageUrls: [
				require("../assets/erste-worte/rakete.jpg"),
				require("../assets/erste-worte/rakete-2.jpg"),
				require("../assets/erste-worte/rakete-StockCake-Rocket-Launch-Skyward_1713084526.jpg"),
				require("../assets/erste-worte/rakete-StockCake-Liftoff-into-Space_1713084393.jpg"),
				require("../assets/erste-worte/rakete-StockCake-Rocket-Launch-Thrust_1713084307.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/rakete.m4a"),
			],
		},
		{id: "frosch",
			imageUrls: [
				require("../assets/erste-worte/frosch-StockCake-Frog-Pond-Reflection_1713083715.jpg"),
				require("../assets/erste-worte/frosch-StockCake-Frog-Pond-Repose_1713083576.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/frosch.m4a"),
			],
		},
		{id: "eule",
			imageUrls: [
				require("../assets/erste-worte/eule-StockCake-Owl-Moonlit-Perch_1713083976.jpg"),
				require("../assets/erste-worte/eule-StockCake-Majestic-Forest-Owl_1713083897.jpg"),
				require("../assets/erste-worte/eule-StockCake-Majestic-Owl-Perched_1713083928.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/eule.m4a"),
			],
		},
		{id: "erdbeere",
			imageUrls: [
				require("../assets/erste-worte/erdbeere-StockCake-Luscious-Ripe-Strawberry_1713084939.jpg"),
				require("../assets/erste-worte/erdbeere-StockCake-Dewy-Strawberry-Close-Up_1713084958.jpg"),
				require("../assets/erste-worte/erdbeere-StockCake-Juicy-red-strawberry_1713084950.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/erdbeere.m4a"),
			],
		},
		{id: "traktor",
			imageUrls: [
				require("../assets/erste-worte/traktor-StockCake-Sunset-Farming-Operations_1713084902.jpg"),
				require("../assets/erste-worte/traktor-StockCake-Stormy-Field-Plowing_1713084813.jpg"),
				require("../assets/erste-worte/traktor-StockCake-Tractor-Tilling-Soil_1713084715.jpg"),
				require("../assets/erste-worte/traktor-StockCake-Rustic-Farm-Tractor_1713084594.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/traktor.m4a"),
			],
		},
		{id: "biene",
			imageUrls: [
				require("../assets/erste-worte/biene-StockCake-Bee-in-Flight_1713083842.jpg"),
				require("../assets/erste-worte/biene-StockCake-Hovering-Honey-Bee_1713083833.jpg"),
				require("../assets/erste-worte/biene-StockCake-Bee-on-Flower_1713083774.jpg"),
				require("../assets/erste-worte/biene-StockCake-Bees-on-Comb_1713083760.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/biene.m4a"),
			],
		},
		{id: "huhn",
			imageUrls: [
				require("../assets/erste-worte/huhn-StockCake-Chicken-Pecking-Ground_1713537464.jpg"),
				require("../assets/erste-worte/huhn-StockCake-Sunlit-Barnyard-Hen_1713537451.jpg"),
				require("../assets/erste-worte/huhn-StockCake-Hen-at-mealtime_1713537439.jpg"),
				require("../assets/erste-worte/huhn-StockCake-Sunlit-Barnyard-Hen_1713537360.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/huhn.m4a"),
			],
		},
		{id: "krokodil",
			imageUrls: [
				require("../assets/erste-worte/krokodil.jpg"),
				require("../assets/erste-worte/krokodil-StockCake-Camouflaged-Alligator-Waiting_1713619097.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/krokodil.m4a"),
			],
		},
		{id: "mond",
			imageUrls: [
				require("../assets/erste-worte/mond-StockCake-Luminous-Full-Moon_1713646131.jpg"),
				require("../assets/erste-worte/mond-StockCake-Moonlit-Ocean-View_1713646122.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/mond.m4a"),
			],
		},
		{id: "moewe",
			imageUrls: [
				require("../assets/erste-worte/moewe-StockCake-Seagull-on-Perch_1713646680.jpg"),
				require("../assets/erste-worte/moewe-StockCake-Seagull-Takes-Flight_1713647191.jpg"),
				require("../assets/erste-worte/moewe-StockCake-Seagull-Sunset-Flight_1713647035.jpg"),
				require("../assets/erste-worte/moewe-StockCake-Seagull-Sunset-Flight_1713646984.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/moewe.m4a"),
			],
		},
		{id: "ball",
			imageUrls: [
				require("../assets/erste-worte/ball-StockCake-Beach-Ball-Fun_1713647636.jpg"),
				require("../assets/erste-worte/ball-StockCake-Baseball-Up-Close_1713648539.jpg"),
				require("../assets/erste-worte/ball-StockCake-Sunset-Basketball-Game_1713648481.jpg"),
				require("../assets/erste-worte/ball-StockCake-Red-ball-solo_1713648355.jpg"),
				require("../assets/erste-worte/ball-StockCake-Nighttime-Soccer-Setting_1713647735.jpg"),
				require("../assets/erste-worte/ball-StockCake-Stadium-Soccer-Night_1713647648.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/ball.m4a"),
			],
		},
		{id: "pizza",
			imageUrls: [
				require("../assets/erste-worte/pizza-StockCake-Cheesy-Pizza-Slice_1713818032.jpg"),
				require("../assets/erste-worte/pizza-StockCake-Delicious-Pizza-Slice_1713818135.jpg"),
				require("../assets/erste-worte/pizza-StockCake-Delicious-Cheese-Pizza_1713818045.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/pizza.m4a"),
			],
		},
		{id: "hubschrauber",
			imageUrls: [
				require("../assets/erste-worte/hubschrauber-StockCake-Helicopter-Mountain-Flight_1713894579.jpg"),
				require("../assets/erste-worte/hubschrauber-StockCake-Sunset-Helicopter-Flight_1713894549.jpg"),
				require("../assets/erste-worte/hubschrauber-StockCake-Hovering-Rescue-Helicopter_1713894490.jpg"),
				require("../assets/erste-worte/hubschrauber-StockCake-Helicopter-Amidst-Clouds_1713894421.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/hubschrauber.m4a"),
			],
		},
		{id: "flugzeug",
			imageUrls: [
				require("../assets/erste-worte/flugzeug-StockCake-Airplane-Cloudy-Skies_1714336869.jpg"),
				require("../assets/erste-worte/flugzeug-StockCake-Airplane-Mid-Flight_1713894894.jpg"),
				require("../assets/erste-worte/flugzeug-StockCake-Flight-Among-Clouds_1714336991.jpg"),
				require("../assets/erste-worte/flugzeug-StockCake-Flying-High-Above_1713894761.jpg"),
				require("../assets/erste-worte/flugzeug-StockCake-Soaring-High-Above_1713894902.jpg"),
				require("../assets/erste-worte/flugzeug-StockCake-Soaring-High-Above_1713894908.jpg"),
				require("../assets/erste-worte/flugzeug-StockCake-Soaring-Red-Plane_1713894854.jpg"),
				require("../assets/erste-worte/flugzeug-StockCake-Soaring-Through-Sky_1713894930.jpg"),
				require("../assets/erste-worte/flugzeug-StockCake-Sunset-Flight-Adventure_1713894800.jpg"),
				require("../assets/erste-worte/flugzeug-StockCake-Sunset-Flight-Adventure_1713894846.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/flugzeug.m4a"),
			],
		},
		{id: "fisch",
			imageUrls: [
				require("../assets/erste-worte/fisch-StockCake-Striped-Fish-Swimming_1713895557.jpg"),
				require("../assets/erste-worte/fisch-StockCake-Vibrant-Aquatic-Life_1713895554.jpg"),
				require("../assets/erste-worte/fisch-StockCake-Underwater-Fish-Scene_1713895552.jpg"),
				require("../assets/erste-worte/fisch-StockCake-Aquatic-Life-Showcased_1713895531.jpg"),
				require("../assets/erste-worte/fisch-StockCake-Elegant-Goldfish-Swimming_1713895528.jpg"),
				require("../assets/erste-worte/fisch-StockCake-Elegant-Goldfish-Swimming_1713895496.jpg"),
				require("../assets/erste-worte/fisch-StockCake-Aquatic-Life-Flourishes_1713895453.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/fisch.m4a"),
			],
		},
		{id: "hase",
			imageUrls: [
				require("../assets/erste-worte/hase-StockCake-Rabbit-Enjoys-Carrot_1714240616.jpg"),
				require("../assets/erste-worte/hase-StockCake-Jumping-White-Rabbit_1714240612.jpg"),
				require("../assets/erste-worte/hase-StockCake-Rabbit-with-Strawberry_1714240609.jpg"),
				require("../assets/erste-worte/hase-StockCake-Alert-Rabbit-Portrait_1714240582.jpg"),
				require("../assets/erste-worte/hase-StockCake-Leaping-Snow-Rabbit_1714240580.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/hase.m4a"),
			],
		},
		{id: "kueken",
			imageUrls: [
				require("../assets/erste-worte/kueken-StockCake-Cute-Chick-Posing_1713537174.jpg"),
				require("../assets/erste-worte/kueken-StockCake-Adorable-Chick-Curiosity_1713537130.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/kueken.m4a"),
			],
		},
		{id: "elefant",
			imageUrls: [
				require("../assets/erste-worte/elefant-StockCake-Elephant-Painting-Art_1713687301.jpg"),
				require("../assets/erste-worte/elefant-StockCake-Majestic-Elephant-Sunset_1713687287.jpg"),
				require("../assets/erste-worte/elefant-StockCake-Majestic-Elephant-Pose_1713687279.jpg"),
				require("../assets/erste-worte/elefant-StockCake-Elephant-at-Sunset_1713687124.jpg"),
				require("../assets/erste-worte/elefant-StockCake-Majestic-Elephant-Silhouette_1713687110.jpg"),
				require("../assets/erste-worte/elefant-StockCake-Majestic-Elephant-Stance_1713687106.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/elefant.m4a"),
			],
		},
		{id: "baum",
			imageUrls: [
				require("../assets/erste-worte/baum-StockCake-Majestic-Oak-Tree_1713687528.jpg"),
				require("../assets/erste-worte/baum-StockCake-Misty-Solitary-Tree_1713687583.jpg"),
				require("../assets/erste-worte/baum-StockCake-Lone-Pine-Standing_1713687580.jpg"),
				require("../assets/erste-worte/baum-StockCake-Lone-Blossoming-Tree_1713687543.jpg"),
				require("../assets/erste-worte/baum-StockCake-Solitary-Oak-Tree_1713687537.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/baum.m4a"),
			],
		},
		{id: "zug",
			imageUrls: [
				require("../assets/erste-worte/zug-StockCake-Sunset-Train-Journey_1713688310.jpg"),
				require("../assets/erste-worte/zug-StockCake-Sunset-Train-Journey_1713688307.jpg"),
				require("../assets/erste-worte/zug-StockCake-Speeding-Bullet-Train_1713688275.jpg"),
				require("../assets/erste-worte/zug-StockCake-Sunset-Train-Journey_1713688273.jpg"),
				require("../assets/erste-worte/zug-StockCake-Train-In-Motion_1713688270.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/zug.m4a"),
			],
		},
		{id: "krebs",
			imageUrls: [
				require("../assets/erste-worte/krebs-StockCake-Crab-in-Sand_1714240636.jpg"),
				require("../assets/erste-worte/krebs-StockCake-Crab-on-Shore_1714240632.jpg"),
				require("../assets/erste-worte/krebs-StockCake-Crab-Underwater-Pose_1714240627.jpg"),
				require("../assets/erste-worte/krebs-StockCake-Crab-on-Shore_1714240624.jpg"),
				require("../assets/erste-worte/krebs-StockCake-Crab-on-Sand_1714240620.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/krebs.m4a"),
			],
		},
		{id: "bus",
			imageUrls: [
				require("../assets/erste-worte/bus-StockCake-City-Bus-Commute_1716040669.jpg"),
				require("../assets/erste-worte/bus-StockCake-City-Bus-Ride_1716040662.jpg"),
				require("../assets/erste-worte/bus-StockCake-Springtime-Bus-Commute_1716040653.jpg"),
				require("../assets/erste-worte/bus-StockCake-Coastal-Bus-Journey_1716040651.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/bus.m4a"),
			],
		},
		{id: "kran",
			imageUrls: [
				require("../assets/erste-worte/kran-StockCake-Sunset-Construction-Silhouette_1716040250.jpg"),
				require("../assets/erste-worte/kran-StockCake-Urban-Construction-Site_1716040247.jpg"),
				require("../assets/erste-worte/kran-StockCake-Tower-Crane-Profile_1716040243.jpg"),
				require("../assets/erste-worte/kran-StockCake-Tower-Crane-Construction_1716040117.jpg"),
				require("../assets/erste-worte/kran-StockCake-Tower-Crane-Structure_1716040088.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/kran.m4a"),
			],
		},
		{id: "brokkoli",
			imageUrls: [
				require("../assets/erste-worte/brokkoli-StockCake-Fresh-Broccoli-Close-up_1715978494.jpg"),
				require("../assets/erste-worte/brokkoli-StockCake-Fresh-Broccoli-Bunch_1715978475.jpg"),
				require("../assets/erste-worte/brokkoli-StockCake-Fresh-Broccoli-Bunch_1715978467.jpg"),
				require("../assets/erste-worte/brokkoli-StockCake-Fresh-Broccoli-Bunch_1715978450.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/brokkoli.m4a"),
			],
		},
		{id: "gurke",
			imageUrls: [
				require("../assets/erste-worte/gurke-StockCake-Fresh-Green-Cucumber_1715962825.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/gurke.m4a"),
			],
		},
		{id: "tomate",
			imageUrls: [
				require("../assets/erste-worte/tomate-StockCake-Fresh-Tomato-Dew_1715962339.jpg"),
				require("../assets/erste-worte/tomate-StockCake-Fresh-Dewy-Tomato_1715962314.jpg"),
				require("../assets/erste-worte/tomate-StockCake-Ripe-red-tomato_1715962312.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/tomate.m4a"),
			],
		},
		{id: "schmetterling",
			imageUrls: [
				require("../assets/erste-worte/schmetterling-StockCake-Butterfly-Floral-Splendor_1714312541.jpg"),
				require("../assets/erste-worte/schmetterling-StockCake-Vibrant-Butterfly-Beauty_1714312495.jpg"),
				require("../assets/erste-worte/schmetterling-StockCake-Butterfly-on-Bloom_1714312491.jpg"),
				require("../assets/erste-worte/schmetterling-StockCake-Vibrant-Butterfly-Perch_1714312406.jpg"),
				require("../assets/erste-worte/schmetterling-StockCake-Butterfly-Floral-Perch_1714312402.jpg"),
				require("../assets/erste-worte/schmetterling-StockCake-Butterfly-Nature-Art_1714312388.jpg"),
				require("../assets/erste-worte/schmetterling-StockCake-Vibrant-Butterfly-Rest_1714312391.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/schmetterling.m4a"),
			],
		},
		{id: "blume",
			imageUrls: [
				require("../assets/erste-worte/blume-StockCake-Blooming-Lily-Flower_1714312077.jpg"),
				require("../assets/erste-worte/blume-StockCake-Dewy-Flower-Bloom_1714312072.jpg"),
				require("../assets/erste-worte/blume-StockCake-Dewy-Red-Flower_1714312059.jpg"),
				require("../assets/erste-worte/blume-StockCake-Flower-Against-Sky_1714312052.jpg"),
				require("../assets/erste-worte/blume-StockCake-Urban-Flower-Glow_1714312047.jpg"),
				require("../assets/erste-worte/blume-StockCake-Vibrant-Orange-Flower_1714311964.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/blume.m4a"),
			],
		},
		{id: "banane",
			imageUrls: [
				require("../assets/erste-worte/banane-StockCake-Sunlit-Single-Banana_1714153768.jpg"),
				require("../assets/erste-worte/banane-StockCake-Peeled-Banana-Display_1714153758.jpg"),
				require("../assets/erste-worte/banane-StockCake-Ripe-Banana-Display_1714153752.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/banane.m4a"),
			],
		},
		{id: "papagei",
			imageUrls: [
				require("../assets/erste-worte/papagei-StockCake-Colorful-Parrot-Perched_1714155029.jpg"),
				require("../assets/erste-worte/papagei-StockCake-Serene-Parrot-Perch_1714155022.jpg"),
				require("../assets/erste-worte/papagei-StockCake-Colorful-Parrot-Perched_1714155020.jpg"),
				require("../assets/erste-worte/papagei-StockCake-Colorful-Parrot-Perched_1714155018.jpg"),
				require("../assets/erste-worte/papagei-StockCake-Vibrant-Parrot-Perched_1714155016.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/papagei.m4a"),
			],
		},
		{id: "schwan",
			imageUrls: [
				require("../assets/erste-worte/schwan-StockCake-Serene-Swan-Silhouette_1714153221.jpg"),
				require("../assets/erste-worte/schwan-StockCake-Serene-Swan-Reflection_1714153218.jpg"),
				require("../assets/erste-worte/schwan-StockCake-Swan-at-Sunset_1714153214.jpg"),
				require("../assets/erste-worte/schwan-StockCake-Serene-Swan-Lake_1714153210.jpg"),
				require("../assets/erste-worte/schwan-StockCake-Serene-Swan-Silhouette_1714153207.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/schwan.m4a"),
			],
		},
		{id: "windrad",
			imageUrls: [
				require("../assets/erste-worte/windrad-StockCake-Renewable-Energy-Source_1713896741.jpg"),
				require("../assets/erste-worte/windrad-StockCake-Wind-Power-Generation_1713896738.jpg"),
				require("../assets/erste-worte/windrad-StockCake-Wind-Power-Generation_1713896736.jpg"),
				require("../assets/erste-worte/windrad-StockCake-Wind-Turbine-Field_1713896732.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/windrad.m4a"),
			],
		},
		{id: "schildkroete",
			imageUrls: [
				require("../assets/erste-worte/schildkroete-StockCake-Hatchling-First-Journey_1713895934.jpg"),
				require("../assets/erste-worte/schildkroete-StockCake-Turtle-On-Beach_1713895875.jpg"),
				require("../assets/erste-worte/schildkroete-StockCake-Majestic-Turtle-Swimming_1713895872.jpg"),
			],
			audioUrls: [
				require("../assets/erste-worte/schildkroete.m4a"),
			],
		},
	],
}

export default function ImageChooserController(props: ImageChooserControllerProps) {
	const [quadFocus, setQuadFocus] = useState<null|number>(null);

	const getFourImages = () => {
		const quad: ImageSetItem[] = [];
		for (let i = 0; i < 4; i++) {
			// const candidates = imageSet.filter(item => item.id !== props.kitem.targetId && !quad.some(candidateItem => candidateItem.id === item.id));
			const candidate = sample(imageSets["alle"].filter(i => !quad.some(q => q.id === i.id)));
			if (!candidate)
				throw new Error("Failed to find decoy. Is the imageset large enough?");
			if (isImageSetItem(candidate))
				quad.push(candidate);
			else
				quad.push({
					id: candidate.id,
					imageUrl: sample(candidate.imageUrls) || "",
					audioUrl: sample(candidate.audioUrls) || "",
				});
		}
		return shuffle(quad); 
	}
	const [images, setImages] = useState(getFourImages());
	
	const handleQuadClick = (index: number) => {
		logger.debug("Image "+index+" clicked.");
		if (quadFocus !== null) {
			logger.debug("Already showing image, ignoring click");
			return;
		}
		setQuadFocus(index);
		playSound({audioUrl: images[index].audioUrl}, false, () => {
			setTimeout(() => {
				logger.debug("New image set");
				setImages(getFourImages());
				setQuadFocus(null);
			}, 1500);
		});
	}
	
	return <>
		<ImageChooserQuadView
			key={images.map(i => i.id).join("-")}
			images={images}
			onImageClick={handleQuadClick}
			focus={quadFocus}
		/>
	</>;
}
